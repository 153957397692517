<template>
  <div>
    <section class="card-container">
      <div class="form-wrapper">
        <p class="title">{{ pageTitle }}</p>
        <div class="hint">
          <el-tooltip placement="right">
            <div slot="content">
              <p>當您的使用者點擊追蹤連結後，會於48小時內紀錄用戶資料</p>
              <br>
              <p>1.期間內點擊連結並註冊，將會為會員貼上註冊標籤、活動標籤</p>
              <br>
              <p>2.期間內先點擊連結未註冊，48小時內成為會員，也會歸因於該活動標籤</p>
              <br>
              <p>3.期間內點擊連結，已是會員者將會貼上活動標籤</p>
              <br>
              <p>4.期間內點擊連結，但期間內未成為會員，將不貼標</p>
            </div>
            <div class="flex items-center" style="gap: 12px">
              <p>連結貼標說明</p>
              <span class="material-icons" style="width: 24px">
                help_outlined
              </span>
            </div>
          </el-tooltip>
        </div>
        <BaseElForm ref="form" :model="formData" :rules="formRules" label-position="top">
          <BaseElFormItem label="連結名稱" prop="name">
            <BaseElInput v-model="formData.name" clearable placeholder="請輸入" />
          </BaseElFormItem>
          <BaseElFormItem label="原始連結" prop="targetLink">
            <BaseElInput v-model="formData.targetLink" clearable :disabled="!!linkId" placeholder="請輸入欲追蹤連結" />
          </BaseElFormItem>
          <BaseElFormItem label="註冊標籤" prop="registerMTags">
            <BaseElSelect
              ref="registerMTags"
              v-model="formData.registerMTags"
              multiple
              collapse-tags
              placeholder="請選擇標籤名稱"
              @focus="openSelectTag('registerMTags')"
            >
              <BaseElSelectOption
                v-for="tag in formData.registerMTagsOptions"
                :key="tag.id"
                :label="tag.name"
                :value="tag.id"
              />
            </BaseElSelect>
          </BaseElFormItem>
          <BaseElFormItem label="活動標籤" prop="actionMTags">
            <BaseElSelect
              ref="actionMTags"
              v-model="formData.actionMTags"
              multiple
              collapse-tags
              placeholder="請選擇標籤名稱"
              @focus="openSelectTag('actionMTags')"
            >
              <BaseElSelectOption
                v-for="tag in formData.actionMTagsOptions"
                :key="tag.id"
                :label="tag.name"
                :value="tag.id"
              />
            </BaseElSelect>
          </BaseElFormItem>
          <BaseElFormItem label="連結開始時間" prop="start">
            <el-date-picker
              v-model="formData.start"
              editable
              type="date"
              :picker-options="pickerOptions"
              placeholder="請選擇日期"
            />
          </BaseElFormItem>
          <BaseElFormItem label="連結結束時間" prop="end">
            <el-date-picker
              v-model="formData.end"
              editable
              type="date"
              placeholder="請選擇日期"
            />
          </BaseElFormItem>
        </BaseElForm>
      </div>
    </section>

    <MemberTagAdvanceSelect
      enableCreate
      :show.sync="dialog.tagSelect"
      :modelValue="formData[tagsData]"
      :options="customTags"
      :max="tagsData === 'registerMTags' ? 1: 0"
      @update:modelValue="getSelectTag"
    />

    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="$router.go(-1)"
      @confirm="handleConfirm"
    />
  </div>
</template>

<script>
import MemberTagAdvanceSelect from '@/components/Select/MemberTagAdvanceSelect.vue'
import { CreateTrackingLink, FindTrackingLink, UpdateTrackingLink } from '@/api/trackingLink'
import formUtils from '@/utils/form'
import { mapGetters } from 'vuex'
import { map, omit, get, filter } from 'lodash'
import { noEmptyRules, urlRules } from '@/validation'
import dayjs from '@/lib/dayjs'
import { useTagStore } from './components/useTagStore'
import { defineComponent, onMounted, computed } from 'vue'

export default defineComponent({
  name: 'EditTrackingLink',
  components: { MemberTagAdvanceSelect },
  setup () {
    const { tags, getAllTags } = useTagStore()

    const customTags = computed(() => filter(tags.value, item => !item.isSystem))
    onMounted(() => getAllTags())
    return {
      tags,
      customTags,
    }
  },
  data: () => ({
    loading: false,
    tagsData: [],
    dialog: {
      tagSelect: false,
    },
    pickerOptions: {
      disabledDate (time) {
        return time.getTime() < dayjs(Date.now()).subtract(1, 'd')
      },
    },
    formData: {
      name: null,
      targetLink: null,
      start: null,
      end: null,
      registerMTags: [],
      registerMTagsOptions: [],
      actionMTags: [],
      actionMTagsOptions: [],
    },
    formRules: {
      name: [noEmptyRules()],
      targetLink: [noEmptyRules(), urlRules()],
      start: [noEmptyRules()],
      end: [noEmptyRules()],
      registerMTags: [noEmptyRules()],
      actionMTags: [noEmptyRules()],
    },
  }),
  computed: {
    ...mapGetters(['shop']),
    pageTitle () {
      if (this.linkId) return '編輯連結'
      return '新增連結'
    },
    disabledConfirm () {
      return false
    },
    linkId () {
      return get(this.$route, 'params.id')
    },
  },
  async mounted () {
    if (this.linkId) await this.findTrackingLink()
  },
  methods: {
    openSelectTag (type) {
      this.$refs[type].blur()
      this.tagsData = type
      this.dialog.tagSelect = true
    },
    async handleConfirm () {
      const isEdit = this.linkId
      if (isEdit) await this.updateTrackingLink()
      if (!isEdit) await this.createTrackingLink()
    },
    async createTrackingLink () {
      this.loading = true
      if (!(await formUtils.checkForm(this.$refs.form))) {
        this.loading = false
        return
      }
      if (!this.checkStartDate()) {
        this.loading = false
        return
      }
      const form = omit(this.formData, ['registerMTagsOptions', 'actionMTagsOptions'])
      const [, err] = await CreateTrackingLink({
        shopId: this.shop,
        ...form,
      })
      this.loading = false
      if (err) {
        this.$message.error(err)
        return
      }
      this.$message.success('新增成功！')
      this.$router.push({ name: 'TrackingLinkManagement' })
    },
    async updateTrackingLink () {
      this.loading = true
      if (!(await formUtils.checkForm(this.$refs.form))) {
        this.loading = false
        return
      }
      if (!this.checkStartDate()) {
        this.loading = false
        return
      }
      const form = omit(this.formData, ['registerMTagsOptions', 'actionMTagsOptions'])
      const [, err] = await UpdateTrackingLink({
        shopId: this.shop,
        id: get(this.$route, 'params.id'),
        ...form,
      })
      this.loading = false
      if (err) {
        this.$message.error(err)
        return
      }
      this.$message.success('更新成功！')
      this.$router.push({ name: 'TrackingLinkManagement' })
    },
    async findTrackingLink () {
      const [res, err] = await FindTrackingLink({
        shopId: this.shop,
        id: this.$route.params.id,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.syncFormData(res)
    },
    syncFormData (res) {
      this.formData.name = res.name
      this.formData.targetLink = res.targetLink
      this.formData.start = res.start
      this.formData.end = res.end
      this.formData.registerMTags = map(res.RegisterMTags, 'id')
      this.formData.registerMTagsOptions = res.RegisterMTags
      this.formData.actionMTags = map(res.ActionMTags, 'id')
      this.formData.actionMTagsOptions = res.ActionMTags
    },
    getSelectTag (val) {
      console.log(val)
      this.formData[this.tagsData] = [...val]
      this.formData[`${this.tagsData}Options`] = this.tags.filter(i => val.includes(i.id))
    },

    checkStartDate () {
      const start = this.formData.start
      const end = this.formData.end
      if (dayjs(start).isAfter(dayjs(end))) {
        this.$message.error('結束時間不可早於開始時間')
        return false
      }
      return true
    },
  },
})
</script>

<style lang="scss" scoped>
.title {
  @apply text-primary-100 font-bold text-lg leading-[21.6px] ;
}
.form-wrapper {
  @apply p-[20px] pl-[29px];
}
.hint {
  @apply inline-flex py-[22px];
}
</style>
